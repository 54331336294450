<template>
    <div class="page-forgot-password">
        <app-branding-logo v-if="!withoutBranding" />

        <form>
            <h2 class="heading">Forgot password?</h2>

            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <p>Please enter the e-mail you use when creating your account, we'll send you instructions to reset your password.</p>

            <app-input v-model="email" type="email" label="E-mail" :error="errors.fields.email" />

            <div class="actions">
                <button class="btn btn-primary btn-send" @click.prevent="send">Send</button>

                <router-link :to="sign_in_link">Go Back</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appError from '@/components/app-error'
import appLoader from '@/components/app-loader'
import appInput from '@/components/app-input'
import appBrandingLogo from '@/components/app-branding-logo'

import UserDetailsValidator from '@/validators/user-details-validator'
import errMessage from '@/helpers/errMessage'

const SPID = 0

export default {
    components: {
        appError,
        appLoader,
        appInput,
        appBrandingLogo,
    },

    props: {
        withoutBranding: { type: Boolean, default: true }
    },

    data() {
        return {
            email: '',

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                email: { rule: 'email', message: 'Please, enter a valid email address' },
            }

            for (const key in fields) {
                if (UserDetailsValidator.isRuleExists(fields[key].rule)) {
                    if (UserDetailsValidator.isInvalid(fields[key].rule, this[key], fields[key].message)) {
                        this.errors.fields[key] = UserDetailsValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        send() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: SPID,
                    Username: this.email,
                    RedirHost: this.reset_password_redirect_host,
                }

                this.$store.dispatch('api_auth/ResetPasswordStep1LocalUser', params)
                    .then(() => {
                        this.errors.show = false
                        this.errors.message = ''

                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message: `Instructions have been successfully sent for ${ this.email }`,
                            type: 'success',
                            delay: 5000,
                        })

                        this.$router.push(this.sign_in_link)
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },
    },

    computed: {
        ...mapGetters([
            'reset_password_redirect_host',
        ]),

        sign_in_link() {
            return {
                name: 'sign-in',
                ...(this.withoutBranding ? {} : { query: { branding: 'true' } }),
            }
        },
    },
}
</script>

<style lang="scss">
.page-forgot-password {
    .app-error {
        margin-bottom: 24px;
    }
}
</style>