<template>
    <div class="app-branding-logo">
        <img :src="src">
    </div>
</template>

<script>
export default {
    data() {
        return {
            publicPath: process.env.BASE_URL,
        }
    },

    computed: {
        src() {
            return `${ this.publicPath }branding-logo.svg`
        },
    },
}
</script>

<style lang="scss">
.app-branding-logo {
    img {
        display: block;
    }
}
</style>